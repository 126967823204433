import React, { memo } from 'react';

// Components
import Header from './Components/Header';
import FooterComp from './Components/Footer';

type Props = {
  children: JSX.Element | string | JSX.Element[];
  auth?: boolean;
};
function Container({ children }: Props) {
  return (
    <>
      <Header />
      {children}
      <FooterComp />
    </>
  );
}

export default memo(Container);
