import React, { memo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import SearchPage from '@/Layout/Home/Components/SearchPage';
import styles from '@/styles/Components/FrontContainer/Header.module.scss';

import MenuList from './MenuList';

const Header = () => {
  const router = useRouter();
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    // Set initial state for mobile view
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Adjust breakpoint as needed
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize(); // Set initial state on component mount

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActiveRoute = (routePath: string) => {
    const cleanPath = routePath.replace(/:[^/]+/g, ''); // Replace dynamic segments with empty string
    return router?.asPath.startsWith(cleanPath);
  };
  return (
    <>
      {!isMobileView ? (
        <div className={styles.importantNot}>
          <div className={`${styles.container} container`}>
            <div className={styles.leftSide}>
              <h4>Important Announcement:</h4>
            </div>
            <div className={styles.rightSide}>
              <p>
                Please note: Do not make any advance payments. We cannot be held responsible for any
                fraudulent&nbsp;activities.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <MenuList isMobileView={isMobileView} />
      {!isActiveRoute('/login') &&
        !isActiveRoute('/register') &&
        !isActiveRoute('/ads') &&
        !isActiveRoute('/ads-post') &&
        !isActiveRoute('/forgot-password') &&
        !isActiveRoute('/reset-password') &&
        !isActiveRoute('/set-password') &&
        !isActiveRoute('/terms-and-condition') &&
        !isActiveRoute('/privacy-policy') &&
        !isActiveRoute('/dashboard') &&
        !isActiveRoute('/contact-us') &&
        !isActiveRoute('/blogs') &&
        !isActiveRoute('/blogs/:path') &&
        !isActiveRoute('/ads-details/:path') &&
        !isActiveRoute('/call-girl-sites') && <SearchPage />}
    </>
  );
};

export default memo(Header);
