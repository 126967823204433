import React, { memo, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { useApp } from '@/components/App';

import { menuData } from './menuData'; // Import the dynamic menu data
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const MenuList = ({ isMobileView }: any) => {
  const { state, dispatch } = useApp();
  const menuRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [scrolling, setScrolling] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Timeout reference for delayed closing
  const hoverStates = useRef<Array<boolean>>(Array(menuData.length).fill(false));

  const toggleHamburgerMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeMenu = () => {
    setIsHamburgerOpen(false);
    setIsDropdownOpen(null);
  };
  const handleMouseEnter = (index: number) => {
    hoverTimeoutRef.current && clearTimeout(hoverTimeoutRef.current); // Clear previous timeout
    hoverStates.current[index] = true;
    setIsDropdownOpen(index);
  };

  const handleMouseLeave = (index: number) => {
    hoverStates.current[index] = false;
    // Set a timeout before closing to allow time for re-entering
    hoverTimeoutRef.current = setTimeout(() => {
      if (!hoverStates.current.some(state => state)) {
        setIsDropdownOpen(null);
      }
    }, 200); // Adjust the delay as needed
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActiveRoute = (routePath: string) => {
    const cleanPath = routePath.replace(/:[^/]+/g, ''); // Replace dynamic segments with empty string
    return router?.asPath.startsWith(cleanPath);
  };
  return (
    <>
      <header
        className={`${scrolling ? 'scrolled' : ''} ${
          isActiveRoute('/login') ||
          isActiveRoute('/register') ||
          isActiveRoute('/ads') ||
          isActiveRoute('/ads-post') ||
          isActiveRoute('/forgot-password') ||
          isActiveRoute('/reset-password') ||
          isActiveRoute('/set-password') ||
          isActiveRoute('/terms-and-condition') ||
          isActiveRoute('/privacy-policy') ||
          isActiveRoute('/dashboard') ||
          isActiveRoute('/contact-us') ||
          isActiveRoute('/blogs') ||
          isActiveRoute('/blogs/:path') ||
          isActiveRoute('/call-girl-sites') ||
          isActiveRoute('/ads-details/:path')
            ? 'header-fixed asd'
            : 'header-fixed'
        }`}
      >
        <div className="container">
          <div className="d-flex align-items-center">
            {!isMobileView ? (
              <DesktopMenu
                isMobileView={isMobileView}
                menuRef={menuRef}
                toggleHamburgerMenu={toggleHamburgerMenu}
                isHamburgerOpen={isHamburgerOpen}
                user={state?.user}
                dispatch={dispatch}
                closeMenu={closeMenu}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                menuData={menuData}
                isDropdownOpen={isDropdownOpen}
              />
            ) : (
              <MobileMenu
                isMobileView={isMobileView}
                menuRef={menuRef}
                toggleHamburgerMenu={toggleHamburgerMenu}
                isHamburgerOpen={isHamburgerOpen}
                user={state?.user}
                dispatch={dispatch}
                closeMenu={closeMenu}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                menuData={menuData}
                isDropdownOpen={isDropdownOpen}
              />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default memo(MenuList);
