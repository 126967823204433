import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from 'react-bootstrap';

import { logout } from '@/utils/helpers';

const CommonNav = ({
  isMobileView,
  dispatch,
  isHamburgerOpen,
  closeMenu,
  user,
  handleMouseEnter,
  handleMouseLeave,
  menuData,
  isDropdownOpen,
}: any) => {
  const logoutFunction = () => {
    dispatch({
      user: null,
    });
    logout();
  };

  return (
    <>
      <nav className={`menu ${isHamburgerOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={closeMenu}>
          ✖
        </button>
        {isMobileView && (
          <ul className="navbar-nav d-flex align-items-center">
            {user ? (
              <div className="after-login d-flex">
                <Link href={'/profile'}>
                  <div className="user-info d-flex align-items-center">
                    <div className="user-img">
                      <Image
                        loading="lazy"
                        height={32}
                        width={32}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/assets/img/no-image.webp';
                        }}
                        src={user.image}
                        alt="User Image"
                      />
                    </div>
                    <div className="user-name">{user.name}</div>
                  </div>
                </Link>
                <div className="sign-out-btn ms-3">
                  <li>
                    <Button onClick={logoutFunction} className="nav-link">
                      <i className="fa fa-sign-out mr-1"></i>Logout
                    </Button>
                  </li>
                </div>
              </div>
            ) : (
              <div className="before-login">
                <ul className="navbar-nav d-flex align-items-center">
                  <li className="nav-item active">
                    <Link className="pink-g-btn d-flex align-items-center justify-content-center btn" href="/login">
                      <i className="fa fa-user mr-1"></i> Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="pink-g-btn d-flex align-items-center justify-content-center btn" href="/register">
                      <i className="fa fa-sign-in mr-1"></i>Sign up
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </ul>
        )}

        <ul className="menu-wrap">
          {menuData.map((menuItem: any, index: number) => (
            <li
              key={index}
              className="dropdown"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {menuItem.subMenu ? (
                <>
                  <Link href={menuItem?.link} className="dropdown-button">
                    {menuItem.title}
                  </Link>
                  {!isMobileView ? (
                    <div className={isDropdownOpen === index ? 'dropdown-menu' : 'dropdown-menu hide'}>
                      <ul>
                        {menuItem.subMenu.map((subItem: { title: string; link: string }, subIndex: number) => (
                          <li key={subIndex}>
                            <Link href={subItem.link} className="dropdown-item">
                              {subItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="dropdown-menu">
                      <ul>
                        {menuItem.subMenu.map((subItem: { title: string; link: string }, subIndex: number) => (
                          <li key={subIndex}>
                            <Link href={subItem.link} className="dropdown-item">
                              {subItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <Link href={menuItem.link}>{menuItem.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default memo(CommonNav);
