import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from 'react-bootstrap';

import { logout } from '@/utils/helpers';

import CommonNav from './CommonNav';

const DesktopMenu = ({
  isMobileView,
  user,
  dispatch,
  menuRef,
  toggleHamburgerMenu,
  isHamburgerOpen,
  closeMenu,
  handleMouseEnter,
  handleMouseLeave,
  menuData,
  isDropdownOpen,
}: any) => {
  const logoutFunction = () => {
    dispatch({
      user: null,
    });
    logout();
  };

  return (
    <>
      <div className={'header-logo'}>
        <Link href="/">
          <Image
            src="/assets/img/logo.webp"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            height={50}
            quality={75}
            width={50}
            alt="Best Website for Adult in India"
          />
        </Link>
      </div>
      <div className="header-right" ref={menuRef}>
        <button className="hamburger-button" onClick={toggleHamburgerMenu}>
          ☰
        </button>
        <div className={`after-login-tabs d-flex ${isHamburgerOpen ? 'open' : ''}`}>
          <CommonNav
            isMobileView={isMobileView}
            dispatch={dispatch}
            user={user}
            isHamburgerOpen={isHamburgerOpen}
            closeMenu={closeMenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            menuData={menuData}
            isDropdownOpen={isDropdownOpen}
          />
          <ul className="navbar-nav d-flex align-items-center">
            {user ? (
              <div className="after-login d-flex">
                <Link href={'/profile'}>
                  <div className="user-info d-flex align-items-center">
                    <div className="user-img">
                      <Image
                        loading="lazy"
                        height={32}
                        width={32}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/assets/img/no-image.webp';
                        }}
                        src={user.image}
                        alt="User Image"
                      />
                    </div>
                    <div className="user-name">{user.name}</div>
                  </div>
                </Link>
                <div className="sign-out-btn ms-3">
                  <li>
                    <Button onClick={logoutFunction} className="nav-link">
                      <i className="fa fa-sign-out mr-1"></i>Logout
                    </Button>
                  </li>
                </div>
              </div>
            ) : (
              <div className="before-login">
                <ul className="navbar-nav d-flex align-items-center">
                  <li className="nav-item active">
                    <Link className="nav-link" href="/login">
                      <i className="fa fa-user mr-1"></i> Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="/register">
                      <i className="fa fa-sign-in mr-1"></i>Sign up
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </ul>
          <Link
            href={user ? '/ads-post/paid' : '/login'}
            className="pink-g-btn d-flex align-items-center justify-content-center btn"
          >
            POST YOUR AD
            <Image src="/assets/img/arrow-right.webp" height={13} width={20} alt="Post Ads" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default memo(DesktopMenu);
