import dynamic from 'next/dynamic';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const FooterCity = dynamic(() => import('./FooterCity'));
const FooterLogo = dynamic(() => import('./FooterLogo'));
const FooterCommanLinks = dynamic(() => import('./FooterCommanLinks'));

import styles from '@/styles/Components/FrontContainer/Footer.module.scss';

const FooterComp = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerMenu}>
        <Container>
          <Row>
            <FooterLogo />
            <FooterCommanLinks />
            <FooterCity />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FooterComp;
