import React, { memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import CommonNav from './CommonNav';

const MobileMenu = ({
  isMobileView,
  user,
  dispatch,
  menuRef,
  toggleHamburgerMenu,
  isHamburgerOpen,
  closeMenu,
  handleMouseEnter,
  handleMouseLeave,
  menuData,
  isDropdownOpen,
}: any) => {
  return (
    <>
      <div className="header-right" ref={menuRef}>
        <button className="hamburger-button" onClick={toggleHamburgerMenu}>
          ☰
        </button>
        <div className={`after-login-tabs d-flex ${isHamburgerOpen ? 'open' : ''}`}>
          <CommonNav
            isMobileView={isMobileView}
            dispatch={dispatch}
            user={user}
            isHamburgerOpen={isHamburgerOpen}
            closeMenu={closeMenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            menuData={menuData}
            isDropdownOpen={isDropdownOpen}
          />
          <div className={'header-logo'}>
            <Link href="/">
              <Image
                src="/assets/img/logo.webp"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                height={40}
                quality={75}
                width={40}
                alt="Best Website for Adult in India"
              />
            </Link>
          </div>

          <Link
            href={user ? '/ads-post/paid' : '/login'}
            className="pink-g-btn d-flex align-items-center justify-content-center btn"
          >
            POST YOUR AD <img loading="lazy" src="/assets/img/arrow-right.webp" alt="Post Ads" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default memo(MobileMenu);
