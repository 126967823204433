const cityData: { [key: string]: { code: string; desc: string; region?: string; default?: boolean }[] } = {
  'all-regions': [
    {
      code: 'adarsh-nagar',
      desc: 'Adarsh Nagar',
      region: 'delhi',
    },

    {
      code: 'agra',
      desc: 'Agra',
      region: 'uttar-pradesh',
    },
    {
      code: 'ahmedabad',
      desc: 'Ahmedabad',
      region: 'gujarat',
    },
    {
      code: 'ahmednagar',
      desc: 'Ahmednagar',
      region: 'maharashtra',
    },

    {
      code: 'ajmer',
      desc: 'Ajmer',
      region: 'rajasthan',
    },

    {
      code: 'aligarh',
      desc: 'Aligarh',
      region: 'uttar-pradesh',
    },

    {
      code: 'alwar',
      desc: 'Alwar',
      region: 'rajasthan',
    },
    {
      code: 'ambala',
      desc: 'Ambala',
      region: 'haryana',
    },

    {
      code: 'amritsar',
      desc: 'Amritsar',
      region: 'punjab',
    },

    {
      code: 'andheri',
      desc: 'Andheri',
      region: 'maharashtra',
    },

    {
      code: 'bhilwara',
      desc: 'Bhilwara',
      region: 'rajasthan',
    },

    {
      code: 'bhiwani',
      desc: 'Bhiwani',
      region: 'haryana',
    },

    {
      code: 'Chennai',
      desc: 'Chennai',
      region: 'tamil-nadu',
    },
    {
      code: 'delhi',
      desc: 'Delhi',
      region: 'delhi',
    },
    {
      code: 'dharamshala',
      desc: 'Dharamshala',
      region: 'himachal-pradesh',
    },
    {
      code: 'dharavi',
      desc: 'Dharavi',
      region: 'maharashtra',
    },

    {
      code: 'goa',
      desc: 'Goa',
      region: 'goa',
    },

    {
      code: 'hyderabad',
      desc: 'Hyderabad',
      region: 'telangana',
    },

    {
      code: 'indore',
      desc: 'Indore',
      region: 'madhya-pradesh',
    },

    {
      code: 'jaipur',
      desc: 'Jaipur',
      region: 'rajasthan',
    },
    {
      code: 'jaisalmer',
      desc: 'Jaisalmer',
      region: 'rajasthan',
    },

    {
      code: 'jodhpur',
      desc: 'Jodhpur',
      region: 'rajasthan',
    },

    {
      code: 'kolkata',
      desc: 'Kolkata',
      region: 'west-bengal',
    },

    {
      code: 'lucknow',
      desc: 'Lucknow',
      region: 'uttar-pradesh',
    },
    {
      code: 'ludhiana',
      desc: 'Ludhiana',
      region: 'punjab',
    },
    {
      code: 'mg-road',
      desc: 'MG Road',
      region: 'karnataka',
    },
    {
      code: 'mohali',
      desc: 'Mohali',
      region: 'punjab',
    },
    {
      code: 'mount-abu',
      desc: 'Mount Abu',
      region: 'rajasthan',
    },
    {
      code: 'mumbai',
      desc: 'Mumbai',
      region: 'maharashtra',
    },
    {
      code: 'noida',
      desc: 'Noida',
      region: 'uttar-pradesh',
    },
    {
      code: 'pune',
      desc: 'Pune',
      region: 'maharashtra',
    },
    {
      code: 'thiruvananthapuram',
      desc: 'Thiruvananthapuram',
      region: 'kerala',
    },
    {
      code: 'udaipur',
      desc: 'Udaipur',
      region: 'rajasthan',
    },
    {
      code: 'vadodara',
      desc: 'Vadodara',
      region: 'gujarat',
    },
  ],
  goa: [
    { code: 'goa', desc: 'Goa', default: false },
    { code: 'panaji', desc: 'Panaji', default: false },
  ],
  delhi: [
    { code: 'delhi', desc: 'Delhi', default: false },
    { code: 'dwarka', desc: 'Dwarka', default: false },
    { code: 'saket', desc: 'Saket', default: false },
    { code: 'munirka', desc: 'Munirka', default: false },
    { code: 'new-delhi', desc: 'New Delhi', default: false },
    { code: 'karol-bagh', desc: 'Karol Bagh', default: false },
    { code: 'south-delhi', desc: 'South Delhi', default: false },
    { code: 'malviya-nagar', desc: 'Malviya Nagar', default: false },
    { code: 'vasant-kunj', desc: 'Vasant Kunj', default: false },
    { code: 'east-delhi', desc: 'East Delhi', default: false },
    { code: 'lajpat-nagar', desc: 'Lajpat Nagar', default: false },
    { code: 'janakpuri', desc: 'Janakpuri', default: false },
    { code: 'kalkaji', desc: 'Kalkaji', default: false },
    { code: 'hauz-khas', desc: 'Hauz Khas', default: false },
    { code: 'green-park', desc: 'Green Park', default: false },
    { code: 'mayur-vihar', desc: 'Mayur Vihar', default: false },
    { code: 'west-delhi', desc: 'West Delhi', default: false },
  ],
  gujarat: [
    {
      code: 'ahmedabad',
      desc: 'Ahmedabad',
      default: false,
    },

    {
      code: 'gandhinagar',
      desc: 'Gandhinagar',
      default: false,
    },
    {
      code: 'rajkot',
      desc: 'Rajkot',
      default: false,
    },
    {
      code: 'vadodara',
      desc: 'Vadodara',
      default: false,
    },
  ],
  haryana: [
    { code: 'gurgaon', desc: 'Gurgaon', default: false },
    { code: 'ambala', desc: 'Ambala', default: false },
  ],

  karnataka: [
    {
      code: 'bangalore',
      desc: 'Bangalore',
      default: false,
    },
    { code: 'mysore', desc: 'Mysore', default: false },
    { code: 'mangalore', desc: 'Mangalore', default: false },
    { code: 'belgaum', desc: 'Belgaum', default: false },
    { code: 'dharwad', desc: 'Dharwad', default: false },
    { code: 'hospet', desc: 'Hospet', default: false },
    { code: 'udupi', desc: 'Udupi', default: false },
    { code: 'chitradurga', desc: 'Chitradurga', default: false },
    { code: 'madikeri', desc: 'Madikeri', default: false },
    { code: 'raichur', desc: 'Raichur', default: false },
    { code: 'chikmagalur', desc: 'Chikmagalur', default: false },
    { code: 'bijapur', desc: 'Bijapur', default: false },
    { code: 'gadag', desc: 'Gadag', default: false },
    { code: 'bhadravathi', desc: 'Bhadravathi', default: false },
  ],

  maharashtra: [
    { code: 'nagpur', desc: 'Nagpur', default: false },
    { code: 'andheri', desc: 'Andheri', default: false },
    { code: 'nerul', desc: 'Nerul', default: false },
    { code: 'hinjewadi', desc: 'Hinjewadi', default: false },
    { code: 'kandivali', desc: 'Kandivali', default: false },
    { code: 'grant-road', desc: 'Grant Road', default: false },
    {
      code: 'mumbai',
      desc: 'Mumbai',
      default: false,
    },
    {
      code: 'mumbai-central',
      desc: 'Mumbai Central',
      default: false,
    },

    {
      code: 'pune',
      desc: 'Pune',
      default: false,
    },
  ],

  punjab: [
    {
      code: 'amritsar',
      desc: 'Amritsar',
      default: false,
    },

    {
      code: 'mohali',
      desc: 'Mohali',
      default: false,
    },

    {
      code: 'pathankot',
      desc: 'Pathankot',
      default: false,
    },
    {
      code: 'patiala',
      desc: 'Patiala',
      default: false,
    },
    { code: 'ludhiana', desc: 'Ludhiana', default: false },
    { code: 'jalandhar', desc: 'Jalandhar', default: false },
  ],
  rajasthan: [
    {
      code: 'ajmer',
      desc: 'Ajmer',
      default: false,
    },

    {
      code: 'alwar',
      desc: 'Alwar',
      default: false,
    },

    {
      code: 'c-scheme',
      desc: 'C Scheme',
      default: false,
    },
    {
      code: 'jaipur',
      desc: 'Jaipur',
      default: false,
    },
    {
      code: 'jaisalmer',
      desc: 'Jaisalmer',
      default: false,
    },

    {
      code: 'jodhpur',
      desc: 'Jodhpur',
      default: false,
    },
    {
      code: 'udaipur',
      desc: 'Udaipur',
      default: false,
    },
  ],
  'tamil-nadu': [
    {
      code: 'chennai',
      desc: 'Chennai',
      default: false,
    },
    { code: 'coimbatore', desc: 'Coimbatore', default: false },
    { code: 'dindigul', desc: 'Dindigul', default: false },
    { code: 'tirunelveli', desc: 'Tirunelveli', default: false },
    { code: 'pollachi', desc: 'Pollachi', default: false },
    { code: 'theni', desc: 'Theni', default: false },
    { code: 'tiruvannamalai', desc: 'Tiruvannamalai', default: false },
    { code: 'pudukkottai', desc: 'Pudukkottai', default: false },
    { code: 'thiruvallur', desc: 'Thiruvallur', default: false },
    { code: 'kancheepuram', desc: 'Kancheepuram', default: false },
  ],
  'andhra-pradesh': [
    { code: 'vijayawada', desc: 'Vijayawada', default: false },
    { code: 'guntur', desc: 'Guntur', default: false },
    { code: 'rajahmundry', desc: 'Rajahmundry', default: false },
    { code: 'visakhapatnam', desc: 'Visakhapatnam', default: false },
    { code: 'vizianagaram', desc: 'Vizianagaram', default: false },
    { code: 'madanapalle', desc: 'Madanapalle', default: false },
    { code: 'srikakulam', desc: 'Srikakulam', default: false },
    { code: 'ongole', desc: 'Ongole', default: false },
    { code: 'bhimavaram', desc: 'Bhimavaram', default: false },
    { code: 'machilipatnam', desc: 'Machilipatnam', default: false },
    { code: 'dharmavaram', desc: 'Dharmavaram', default: false },
    { code: 'chilakaluripet', desc: 'Chilakaluripet', default: false },
    { code: 'narasaraopet', desc: 'Narasaraopet', default: false },
    { code: 'hindupur', desc: 'Hindupur', default: false },
  ],
  'uttar-pradesh': [
    {
      code: 'agra',
      desc: 'Agra',
      default: false,
    },

    { code: 'lucknow', desc: 'Lucknow', default: false },
    { code: 'noida', desc: 'Noida', default: false },
    { code: 'ghaziabad', desc: 'Ghaziabad', default: false },
    { code: 'kanpur', desc: 'Kanpur', default: false },
    { code: 'vaishali', desc: 'Vaishali', default: false },
  ],
  uttarakhand: [
    {
      code: 'dehradun',
      desc: 'Dehradun',
      default: false,
    },
    { code: 'rishikesh', desc: 'Rishikesh', default: false },
  ],
  'west-bengal': [{ code: 'kolkata', desc: 'Kolkata', default: false }],
  chandigarh: [{ code: 'chandigarh', desc: 'Chandigarh', default: false }],
  'jammu-kashmir': [
    { code: 'jammu', desc: 'Jammu', default: false },
    { code: 'srinagar', desc: 'Srinagar', default: false },
  ],
  kerala: [
    { code: 'kochi', desc: 'Kochi', default: false },
    { code: 'ernakulam', desc: 'Ernakulam', default: false },
    { code: 'thrissur', desc: 'Thrissur', default: false },
    { code: 'palakkad', desc: 'Palakkad', default: false },
    { code: 'kannur', desc: 'Kannur', default: false },
    { code: 'kottayam', desc: 'Kottayam', default: false },
    { code: 'kollam', desc: 'Kollam', default: false },
    { code: 'kozhikode', desc: 'Kozhikode', default: false },
    { code: 'alappuzha', desc: 'Alappuzha', default: false },
    { code: 'wayanad', desc: 'Wayanad', default: false },
    { code: 'munnar', desc: 'Munnar', default: false },
    { code: 'idukki', desc: 'Idukki', default: false },
    { code: 'kasaragod', desc: 'Kasaragod', default: false },
  ],
  'himachal-pradesh': [
    { code: 'manali', desc: 'Manali', default: false },
    { code: 'dharamshala', desc: 'Dharamshala', default: false },
  ],
  bihar: [{ code: 'patna', desc: 'Patna', default: false }],
  odisha: [{ code: 'bhubaneswar', desc: 'Bhubaneswar', default: false }],
  telangana: [
    { code: 'nizamabad', desc: 'Nizamabad', default: false },
    { code: 'warangal', desc: 'Warangal', default: false },
    { code: 'medak', desc: 'Medak', default: false },
    { code: 'telangana', desc: 'Telangana', default: false },
  ],
  'madhya-pradesh': [{ code: 'bhopal', desc: 'Bhopal', default: false }],
  nagaland: [{ code: 'nagaland', desc: 'Nagaland', default: false }],
  'andaman-nicobar-islands': [{ code: 'port-blair', desc: 'Port Blair', default: false }],
  puducherry: [{ code: 'puducherry', desc: 'Puducherry', default: false }],
};

export default cityData;
